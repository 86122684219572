

























































  import { defineComponent } from '@vue/composition-api'

interface SelectItem {
  id: string
  label: string
}

  export default defineComponent({
    name: 'SelectSearch',
    props: {
      items: {
        type: Array,
        required: true,
      },
      value: {
        type: [Object, Array, String],
        required: false,
        default: () => null,
      },
      btnTitle: {
        type: String,
        required: false,
      },
      label: {
        type: String,
        required: true,
      },
      placeholder: {
        type: String,
        default: 'Recherche',
      },
      disabled: {
        type: Boolean,
        required: false,
      },
      itemText: {
        required: false,
        type: [String, Function],
        default: (item: SelectItem) => {
          return item.label
        },
      },
      clearable: {
        type: Boolean,
        required: false,
        default: false,
      },
      dense: {
        type: Boolean,
        required: false,
        default: false,
      },
      outlined: {
        type: Boolean,
        required: false,
        default: true,
      },
      hideDetails: {
        type: String,
        required: false,
        default: undefined,
      },
      rules: {
        type: String,
        required: false,
        default: '',
      },
      isEditable: {
        type: Boolean,
        required: false,
        default: false,
      },
      searching: {
        type: Boolean,
        required: false,
        default: false,
      },
      multiple: {
        type: Boolean,
        required: false,
        default: false,
      },
      chips: {
        type: Boolean,
        required: false,
        default: false,
      },
      persistentHint: {
        type: Boolean,
        required: false,
        default: false,
      },
      singleLine: {
        type: Boolean,
        required: false,
        default: false,
      },
      classes: {
        type: String,
        required: false,
      },
      readonly: {
        type: Boolean,
        required: false,
        default: false,
      },
      returnObject: {
        type: Boolean,
        required: false,
        default: false,
      },
      loading: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    data () {
      return {
        selectedItem: null as any,
        searchText: '',
        debounceTimeoutId: 0,
      }
    },
    computed: {
      itemsComputed (): any {
        if (this.multiple && this.selectedItem && this.selectedItem.length) {
          const fusion: any = [
            ...(this.selectedItem as SelectItem[]),
            ...(this.items as SelectItem[]),
          ]
          return [
            // remove duplicates from the fusion
            ...new Map(
              fusion.map((item: { id: any }) => [item.id, item]),
            ).values(),
          ]
        }
        return this.items
      },
    },
    created () {
      this.selectedItem = this.value
    },
    watch: {
      value () {
        this.selectedItem = this.value
      },
      async searchText () {
        if (this.selectedItem) {
          let currentLabel
          if (typeof this.itemText !== 'string') {
            currentLabel = (this.itemText as CallableFunction)(this.selectedItem)
          } else {
            currentLabel = this.itemText
          }
          if (this.searchText === currentLabel) {
            return
          }
        }
        window.clearTimeout(this.debounceTimeoutId)
        this.debounceTimeoutId = window.setTimeout(async () => {
          await this.$emit('search', this.searchText)
        }, 200)
      },
    },
    methods: {
      sendValue () {
        this.$emit('input', this.selectedItem)
      },
      updateField () {
        this.$emit('update')
      },
    },
  })
